import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import {
  Handyman,
  AdminPanelSettings,
  AssignmentTurnedIn,
  SupportAgent,
  ExpandLess,
  ExpandMore,
  Logout,
  Login,
  Menu,
  Receipt,
  Description,
  QrCode2,
  ViewList,
  PersonOff,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import DarkModeToggle from '@/components/DarkModeToggle';

// const sigItems = [
//   { text: 'Cortina', href: '/signature/ck' },
//   { text: 'Niico', href: '/signature/niico' },
// ];

const docItems = [{ text: 'Email Signatures', href: '/docs/signature' }];

export const SubNav = ({ icon, primary, items }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  // Function to handle closing the sub-menu if the click is outside of the component
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Listen for clicks outside of the component to close the sub-menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} style={{ color: theme.palette.primary.main }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <Link href={item.href} key={index}>
              <ListItem button>
                <ListItemText
                  sx={{ '& .MuiListItemText-primary': { textDecoration: 'none' } }}
                  inset
                  primary={item.text}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

SubNav.propTypes = {
  icon: PropTypes.element.isRequired,
  primary: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const Sidebar = ({ isAuthenticated, user, groups, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <Menu />
            </IconButton>
            {children}
            <DarkModeToggle />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={isOpen} onClose={toggleDrawer(false)}>
        <List>
          {user?.is_staff && (
            <Link href="/api/admin" underline="hover">
              <ListItem button key="admin">
                <ListItemIcon>
                  <AdminPanelSettings color="primary" />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            </Link>
          )}
          {/* {isAuthenticated && (
            <Link href="/phone" underline="hover">
              <ListItem button key="phone">
                <ListItemIcon>
                  <Phone color="primary" />
                </ListItemIcon>
                <ListItemText primary="Phone Directory" />
              </ListItem>
            </Link>
          )} */}
          {isAuthenticated && (
            <Link href="/vacation" underline="hover">
              <ListItem button key="vacation">
                <ListItemIcon>
                  <PersonOff color="primary" />
                </ListItemIcon>
                <ListItemText primary="Absences and Vacations" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <SubNav primary="Docs" icon={<Description color="primary" />} items={docItems} />
          )}
          {/* {isAuthenticated && groups?.includes('SuperMod') && (
            <SubNav
              primary="Signature Generator"
              icon={<Gesture color="primary" />}
              items={sigItems}
            />
          )} */}
          {isAuthenticated && groups?.includes('SuperMod') && (
            <Link href="/qrcodes" underline="hover">
              <ListItem button key="logs">
                <ListItemIcon>
                  <QrCode2 color="primary" />
                </ListItemIcon>
                <ListItemText primary="QR Code Generator" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && groups?.includes('Service') && (
            <Link href="/service" underline="hover">
              <ListItem button key="service">
                <ListItemIcon>
                  <Handyman color="primary" />
                </ListItemIcon>
                <ListItemText primary="Service Order Logs" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && groups?.includes('Card') && (
            <Link href="/reports" underline="hover">
              <ListItem button key="logs">
                <ListItemIcon>
                  <Receipt color="primary" />
                </ListItemIcon>
                <ListItemText primary="Purchase Logs" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && groups?.includes('Forms') && (
            <Link href="/forms/requisition" underline="hover">
              <ListItem button key="requisition">
                <ListItemIcon>
                  <ViewList color="primary" />
                </ListItemIcon>
                <ListItemText primary="Requisition Forms" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && groups?.includes('QCC') && (
            <Link href="/forms/qcc" underline="hover">
              <ListItem button key="qcc">
                <ListItemIcon>
                  <AssignmentTurnedIn color="primary" />
                </ListItemIcon>
                <ListItemText primary="Quality Check Completion Forms" />
              </ListItem>
            </Link>
          )}
          {/* {isAuthenticated && groups?.includes('Schedule') && (
            <Link href="/schedule" underline="hover">
              <ListItem button key="schedule">
                <ListItemIcon>
                  <CalendarMonth color="primary" />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
              </ListItem>
            </Link>
          )} */}
          {!isAuthenticated && (
            <Link href="/api/login" underline="hover">
              <ListItem button key="login">
                <ListItemIcon>
                  <Login color="primary" />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/support" underline="hover">
              <ListItem button key="support">
                <ListItemIcon>
                  <SupportAgent color="primary" />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/api/logout" underline="hover">
              <ListItem button key="logout">
                <ListItemIcon>
                  <Logout color="primary" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </Link>
          )}
        </List>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    full_name: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  user: {
    username: '',
    email: '',
    is_superuser: false,
    is_staff: false,
    full_name: '',
  },
  children: null,
};
