import { useContext } from 'react';
import Head from 'next/head';
import { Box, Container, Typography, Tooltip } from '@mui/material';
import Image from 'next/image';
import { useAuth } from '@/contexts/AuthContext';
import { Sidebar } from '@/components/Navigation';
import { ThemeContext } from '@/contexts/ThemeContext';

export default function Home() {
  const { mode } = useContext(ThemeContext);
  const { isAuthenticated, user, groups } = useAuth();

  return (
    <Container maxWidth="xl">
      <Head>
        <title>Cortina Kitchens Inc.</title>
        <meta name="description" content="Cortina Kitchens Inc. - Custom Kitchen Designs" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Sidebar isAuthenticated={isAuthenticated} user={user} groups={groups} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'auto',
          height: 'calc(100vh - 128px)',
          fontFamily: 'Cormorant',
          textAlign: 'center',
        }}
      >
        <Image
          style={{
            width: '60rem',
            maxWidth: '100%',
            height: 'auto',
            filter: mode === 'dark' ? 'invert(1)' : 'none',
          }}
          src="/imgs/CKLogo.png"
          alt="Logo"
          width={2000}
          height={512}
          priority
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'background.paper',
          py: 3,
          mt: 'auto',
          textAlign: 'center',
        }}
      >
        <Tooltip title="Created By: Christopher Couture">
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Cortina Kitchens Inc. All rights reserved.
          </Typography>
        </Tooltip>
      </Box>
    </Container>
  );
}
